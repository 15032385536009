@use "./abstracts/" as *;

* {
  cursor: default;
  font-family: $font-primary;
  color: var(--dark);
}

a {
  cursor: pointer;
  text-decoration: none !important;
  color: inherit;
}

html {
  scroll-behavior: smooth;
  overflow-y: overlay;
  overflow-x: hidden;
}

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
  background-color: var(--light);

  @media screen and (max-width: $mobile_res) {
    width: 96%;
    margin: auto;
  }
}

h1 {
  font-size: 48px;
  font-weight: 600;

  @media screen and (max-width: $mobile_res) {
    text-align: center;
  }
}

h5 {
  font-size: 20px;
  font-weight: 400;

  @media screen and (max-width: $mobile_res) {
    text-align: center;
  }
}

button {
  outline: none !important;
  border: none !important;
  box-shadow: none !important;
  font-weight: 500 !important;

  &.btn-primary {
    background-color: rgba(var(--primary-rgb), 0.75) !important;
    transition: none;

    &:hover {
      background-color: var(--primary) !important;
    }
  }

  &.btn-secondary {
    background-color: rgba(var(--light-accent-rgb), 0.6) !important;
    color: var(--dark) !important;
    transition: none;

    &:hover {
      background-color: rgba(var(--light-accent-rgb), 1) !important;
    }
  }
}

.form-control {
  border: 1px solid rgba(var(--dark-rgb), 0.3) !important;
  border-radius: 10px !important;
  height: 100%;
  box-shadow: none !important;
  padding: 10px 18px !important;
  background-color: var(--light) !important;
  color: var(--dark) !important;
  outline: none !important;
  cursor: text;

  &:focus,
  &:active,
  &:hover {
    outline: 1px solid rgba(var(--primary-rgb), 0.15) !important;
  }

  &::placeholder {
    color: rgba(var(--dark-rgb), 0.4) !important;
  }
}

.search-input-group {
  width: fit-content !important;

  .input-group-text {
    border-radius: 0 10px 10px 0 !important;
    border: 1px solid rgba(var(--dark-rgb), 0.3) !important;
    border-left: none !important;
    background-color: transparent;

    svg {
      fill: rgba(var(--primary-rgb), 0.5);
      width: 28px;
      cursor: pointer;
    }

    &:hover svg {
      fill: rgba(var(--primary-rgb), 0.75);
    }
  }

  .search-bar {
    border-radius: 10px 0 0 10px !important;
  }

  .search-bar::placeholder {
    color: rgba(var(--dark-rgb), 0.4) !important;
  }
}

.dropdown {
  position: relative;
  .nav-link {
    background-color: transparent;
  }

  .dropdown-menu {
    margin-top: 15px !important;
    border-radius: 15px;
    padding: 10px;
    border: 1px solid rgba(var(--dark-rgb), 0.05);
    background-color: var(--light-accent) !important;
    box-shadow: $boxShadow1 !important;
    max-height: 320px;
    overflow-y: scroll;

    @media screen and (max-width: $mobile_res) {
      position: absolute;
      margin: auto;
      left: 0;
      width: fit-content;
      max-height: 160px;
      margin-top: 10px !important;
      overflow-y: scroll;
    }

    .dropdown-item {
      padding: 8px 14px;
      border-radius: 10px;
      color: var(--dark);

      &:hover {
        background-color: var(--light) !important;
      }
    }
  }
}

.theme-btn {
  background-color: transparent;

  svg {
    cursor: pointer;
    width: 24px;
    fill: var(--dark);
    stroke: var(--dark);
  }
}

.navbar {
  border-bottom: 1px solid rgba(var(--dark-rgb), 0.1);
  background-color: rgba(var(--light-rgb), 0.9);
  border-radius: 0 0 20px 20px;
  backdrop-filter: blur(10px);

  .navbar-brand {
    img {
      width: 150px;
      cursor: pointer;
    }

    @media screen and (max-width: $mobile_res) {
      img {
        width: 80px;
      }
    }
  }

  form {
    width: 30%;
    margin-left: 30px;
  }

  @media screen and (max-width: $mobile_res) {
    .nav-item {
      width: fit-content;
      margin: -3px auto;
    }

    form {
      width: fit-content;
      margin: auto;
    }

    .navbar-nav {
      border-top: 1px solid rgba(var(--dark-rgb), 0.1);
      margin-top: 15px;
      padding-top: 20px;
    }
  }

  .nav-item {
    display: flex;
    border-radius: 50px !important;
    min-width: 48px;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    svg {
      cursor: pointer;
      width: 24px;
      fill: var(--dark);
      stroke: var(--dark);
    }

    &:hover {
      background-color: var(--light-accent) !important;
    }

    &.outline {
      svg {
        fill: none !important;
        stroke: var(--dark);
        stroke-width: 2px;
      }
    }
  }

  .nav-link {
    background-color: transparent;
    color: var(--dark) !important;
    padding: 10px 20px !important;
    border-radius: 50px !important;
    font-weight: 400 !important;

    &.active {
      font-weight: 600 !important;
      background-color: var(--light-accent) !important;
    }
  }
}

hr {
  border-color: white !important;
}

.vr {
  height: auto;
  background-color: var(--dark);
  margin: 10px auto;
}

.vr-1 {
  width: 1px;
}

.vr-2 {
  width: 2px;
}

.glass-wrapper {
  position: absolute;
  top: 60px;
  left: 0;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  z-index: -1;
}

.circle-1,
.circle-2 {
  position: relative;
  background-color: #ef233c;
  width: 320px;
  height: 320px;
  margin: 80px;
  border-radius: 50%;
  mix-blend-mode: multiply;
  filter: blur(50px);
  opacity: 0.2;

  @media screen and (max-width: $mobile_res) {
    width: 50vw;
    height: 50vw;
  }
}

.circle-2 {
  margin-top: -160px;
  background-color: #04868b;
}

.slide-btn-wrapper {
  @media screen and (max-width: $mobile_res) {
    justify-content: center;
  }
}

.btn-slide {
  display: flex;
  outline: none;
  border: 1px solid rgba(var(--dark-rgb), 0.15);
  background-color: rgba(var(--light-accent-rgb), 0.5);
  border-radius: 50px;
  padding: 8px;
  justify-content: center;

  &:hover {
    border: 1px solid rgba(var(--dark-rgb), 0.5) !important;
  }

  svg {
    width: 22px;
    fill: rgba(var(--dark-rgb), 0.5);
    cursor: pointer;
    z-index: 0;
  }
}

.home-article-wrapper {
  position: relative;

  .home-article {
    background: rgba(var(--light-rgb), 0.1);
    border-radius: 20px;
    backdrop-filter: blur(16px);
    border: 1px solid rgba(var(--light-rgb), 0.1);
    box-shadow: 0 25px 50px -12px rgb(0 0 0 / 0.25);
    padding: 40px;

    @media screen and (max-width: $mobile_res) {
      margin: auto;
      margin-top: -10%;
      width: 90%;

      h4 {
        font-size: 20px !important;
      }
    }

    h4 {
      font-weight: 500;
      color: var(--dark);
      font-size: 24px;

      &:hover {
        color: $primary-accent;
      }
    }

    .author-wrapper {
      .img-wrapper {
        width: 60px;
        height: 60px;
        overflow: hidden;
        border-radius: 50%;

        img {
          width: 100%;
          object-fit: cover;
        }
      }

      .author-details {
        p {
          margin: 1px 0;

          &.name {
            font-size: 15px;
            font-weight: 500;
            opacity: 0.95;
          }

          &.date {
            font-size: 14px;
            opacity: 0.6;
          }
        }
      }
    }

    .action-wrapper {
      .likes {
        display: flex;
        gap: 8px;
        align-items: center;
        padding: 6px 12px;
        border-radius: 50px;
        background-color: rgba(var(--primary-rgb), 0.2);
        cursor: pointer;

        p {
          margin: auto;
          font-size: 14px;
          cursor: pointer;
        }

        svg {
          width: 24px;
          fill: rgba(var(--primary-rgb), 0.75);
          cursor: pointer !important;
        }

        &:hover {
          background-color: rgba(var(--primary-rgb), 0.5);

          svg {
            fill: var(--primary);
          }
        }
      }

      p {
        font-size: 13px;
        opacity: 0.8;
      }
    }
  }

  .article-img {
    overflow: hidden;
    height: 500px;
    border-radius: 30px;
    padding: 0;
    margin-left: -10%;

    @media screen and (max-width: $mobile_res) {
      margin: 0 auto;
      height: 300px;
      width: 90%;

      img {
        height: 100%;
        object-fit: cover;
      }
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center;
      cursor: pointer;
    }
  }
}

.trending-article {
  display: flex;
  align-items: flex-end;
  position: relative;
  width: 90%;
  height: 480px;
  border-radius: 30px;
  padding: 15px 30px;
  margin: 0 auto;
  cursor: pointer;

  .content {
    h4 {
      font-size: 20px;
      font-weight: 600;
      color: white;
      cursor: pointer;
    }

    p {
      font-size: 14px;
      font-weight: 500;
      color: white;
    }
  }

  .img-wrapper {
    position: absolute;
    top: 0;
    left: 0;
    margin: auto;
    width: 100%;
    height: 100%;
    overflow: hidden;
    border-radius: 30px;
    @include transition;
    z-index: -1;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center;
      @include transition;
      filter: brightness(0.75);
    }
  }

  &:hover {
    .img-wrapper {
      img {
        transform: scale(1.2);
        filter: brightness(1);
      }
    }
  }
}

.latest-article {
  background: rgba(var(--dark-rgb), 0.01);
  border-radius: 20px;
  backdrop-filter: blur(16px);
  border: 1px solid rgba(var(--dark-rgb), 0.1);

  .main-img-wrapper {
    width: 100%;
    border-radius: 20px 20px 0 0;
    overflow: hidden;

    img {
      width: 100%;
      object-fit: cover;
      cursor: pointer;
    }
  }

  @media screen and (max-width: $mobile_res) {
    margin: auto;
    width: 90%;

    h4 {
      font-size: 16px !important;
    }
  }

  .content {
    padding: 20px;

    h4 {
      font-weight: 600;
      color: var(--dark);
      font-size: 24px;
      cursor: pointer;
    }

    p {
      font-size: 15px;

      strong {
        font-weight: 400;
      }
    }

    .author-wrapper {
      .img-wrapper {
        width: 60px;
        height: 60px;
        overflow: hidden;
        border-radius: 50%;

        img {
          width: 100%;
          object-fit: cover;
        }
      }

      .author-details {
        p {
          margin: 1px 0;

          &.name {
            font-size: 15px;
            font-weight: 500;
            opacity: 0.95;
          }

          &.date {
            font-size: 14px;
            opacity: 0.6;
          }
        }
      }
    }

    .action-wrapper {
      .likes {
        display: flex;
        gap: 8px;
        align-items: center;
        padding: 6px 12px;
        border-radius: 50px;
        background-color: rgba(var(--primary-rgb), 0.2);

        p {
          margin: auto;
          font-size: 14px;
          cursor: pointer;
        }

        svg {
          width: 24px;
          fill: rgba(var(--primary-rgb), 0.75);
          cursor: pointer !important;
        }

        &:hover {
          background-color: rgba(var(--primary-rgb), 0.5);

          svg {
            fill: var(--primary);
          }
        }
      }

      p {
        font-size: 13px;
        opacity: 0.8;
      }
    }
  }

  &:hover {
    background: rgba(var(--dark-rgb), 0.03);
    box-shadow: 0 10px 25px -5px rgb(0 0 0 / 0.05),
      0 8px 20px -6px rgb(0 0 0 / 0.05);
  }
}

.latest-article-mini {
  margin-bottom: 25px;
  @include transition;

  @media screen and (max-width: $mobile_res) {
    margin-top: 25px;
  }

  .content {
    h4 {
      font-size: 16px;
    }

    .author-wrapper {
      margin-top: -10px !important;

      .img-wrapper {
        display: flex;
        align-items: center;
        width: 30px;
      }

      .author-details .name {
        font-size: 13px !important;
      }

      @media screen and (max-width: $mobile_res) {
        .img-wrapper {
          width: 40px;
        }

        .author-details .name {
          font-size: 10px !important;
        }
      }
    }

    .action-wrapper {
      .likes {
        padding: 4px 8px;

        p {
          font-size: 13px;
          cursor: pointer;
        }

        svg {
          width: 20px;
          fill: rgba(var(--primary-rgb), 0.75);
          cursor: pointer !important;
        }

        &:hover {
          background-color: rgba(var(--primary-rgb), 0.5);

          svg {
            fill: var(--primary);
          }
        }
      }
    }
  }

  .main-img-wrapper {
    max-width: 120px;
    max-height: 120px;
    overflow: hidden;
    margin: 20px;
    border-radius: 5px;

    img {
      width: 100%;
      object-fit: contain;

      cursor: pointer;
    }
  }
}

.single-article {
  .header-wrapper {
    display: flex;
    align-items: center;
    position: relative;
    height: 560px;
    margin: 0;

    @media screen and (max-width: $mobile_res) {
      height: fit-content;
      padding: 40px 20px;
      border-radius: 10px;
    }

    .content {
      width: fit-content;
      color: var(--light);

      h2 {
        font-size: 36px;
        font-weight: 600;
        color: white;
        white-space: pre-wrap;
      }

      .author-wrapper {
        .author-img-wrapper {
          width: 60px;
          height: 60px;
          overflow: hidden;
          border-radius: 50%;

          img {
            width: 100%;
            object-fit: cover;
          }
        }

        .author-details {
          p {
            color: white;
            margin: 1px 0;

            &.name {
              font-size: 15px;
              font-weight: 500;
              opacity: 0.95;
            }

            &.date {
              font-size: 14px;
              opacity: 0.6;
            }
          }
        }
      }

      .action-wrapper {
        @media screen and (max-width: $mobile_res) {
          .likes {
            background-color: rgba(var(--dark-rgb), 0.5) !important;
          }
        }

        .likes {
          display: flex;
          gap: 8px;
          align-items: center;
          padding: 6px 12px;
          border-radius: 50px;
          background-color: rgba(var(--primary-rgb), 0.2);

          p {
            color: white;
            margin: auto;
            font-size: 14px;
            font-weight: 600;
          }

          svg {
            width: 24px;
            fill: rgba(var(--primary-rgb), 0.75);
            cursor: pointer !important;
          }

          &:hover {
            background-color: rgba(var(--primary-rgb), 0.5);

            svg {
              fill: var(--primary);
            }
          }
        }

        p {
          font-size: 13px;
          opacity: 0.8;
        }
      }
    }

    .img-wrapper {
      position: absolute;
      right: 0;
      top: 0;
      height: inherit;
      overflow: hidden;
      z-index: -1;

      @media screen and (max-width: $mobile_res) {
        width: 100%;
        height: 100%;
        border-radius: 10px;
        filter: brightness(0.5);

        img {
          width: 100%;
          object-position: center !important;
        }
      }

      img {
        height: 100%;
        object-fit: cover;
        object-position: top;
      }
    }
  }

  .content {
    p {
      font-size: 18px;
      line-height: 32px;

      @media screen and (max-width: $mobile_res) {
        img {
          height: 100%;
        }
      }

      img {
        width: 100%;
        height: 100%;
        overflow: hidden;
        object-fit: contain;
        margin: 10px 0;
        border-radius: 10px;
      }
    }

    .article-tags-wrapper {
      display: flex;
      flex-wrap: wrap;
      gap: 12px;
      list-style: none;
      padding: 0;

      li {
        width: fit-content;
        background-color: rgba(var(--light-accent-rgb), 0.8);
        border: 1px solid rgba(var(--dark-rgb), 0.085);
        padding: 8px 12px;
        border-radius: 6px;
        font-size: 14px;
        border: 1px solid transparent;
        cursor: pointer;

        &:hover {
          background-color: rgba(var(--light-accent-rgb), 0.5);
          border: 1px solid rgba(var(--dark-rgb), 0.1);
        }
      }
    }

    .tags-wrapper {
      background-color: var(--light-accent);
      padding: 20px;
      border-radius: 15px;

      h5 {
        display: flex;
        font-weight: 600;
        font-size: 20px;
        gap: 10px;
        color: var(--dark);

        svg {
          width: 24px;
          stroke: var(--primary);
        }
      }

      hr {
        border-color: var(--dark) !important;
      }

      ul {
        display: flex;
        flex-wrap: wrap;
        gap: 12px;
        list-style: none;
        padding: 0;

        li {
          width: fit-content;
          background-color: var(--light);
          padding: 8px 12px;
          border-radius: 6px;
          font-size: 14px;
          border: 1px solid transparent;
          cursor: pointer;

          &:hover {
            border: 1px solid rgba(var(--dark-rgb), 0.1);
          }
        }
      }
    }

    .popular-articles {
      background-color: var(--light-accent);
      border-radius: 15px;

      .article {
        position: relative;

        h5 {
          display: flex;
          font-weight: 600;
          font-size: 20px;
          gap: 10px;
          padding: 20px;
          color: var(--dark);
          margin-bottom: 0;

          svg {
            width: 24px;
            stroke: var(--primary);
          }
        }

        .content {
          padding: 20px;

          h4 {
            font-weight: 600;
            color: var(--dark);
            font-size: 16px;
            line-height: 24px;
            margin: 5px 0;
            cursor: pointer;
          }

          .author-wrapper {
            .img-wrapper {
              width: 30px;
              height: 30px;
              overflow: hidden;
              border-radius: 50%;

              img {
                width: 100%;
                object-fit: cover;
              }
            }

            .author-details {
              font-size: 12px;
              font-weight: 500;
              opacity: 0.95;
            }
          }
        }

        .main-img-wrapper {
          max-width: 120px;
          max-height: 120px;
          overflow: hidden;
          margin: 20px;
          border-radius: 5px;

          img {
            width: 100%;
            object-fit: contain;
            border-radius: 5px;
            cursor: pointer;
          }
        }

        &:hover:not(:first-child) {
          background-color: rgba(var(--dark-rgb), 0.05);

          &:last-child {
            border-radius: 0 0 15px 15px;
          }
        }

        &:not(&:first-child) {
          &::before {
            content: "";
            position: absolute;
            height: 1px;
            width: 100%;
            top: 0;
            background-color: rgba(var(--dark-rgb), 0.1);
          }
        }
      }
    }
  }
}

.article-img-wrapper {
  width: 100%;
  height: 360px;
  overflow: hidden;
  border-radius: 0 0 10px 10px;

  img {
    width: 100%;
    object-fit: cover;
  }
}

.login-form {
  width: fit-content;
  margin: auto;
  background-color: rgba(var(--dark-rgb), 0.05);
  border: 1px solid rgba(var(--dark-rgb), 0.05);
  padding: 60px 40px;
  border-radius: 15px;

  @media screen and (max-width: $mobile_res) {
    .form-group {
      min-width: 65vw !important;
      margin: 20px auto;
    }
  }

  h5 {
    text-align: center;
  }

  label {
    margin-bottom: 8px;
    font-size: 12px;
  }

  .form-group {
    min-width: 400px;
    margin: 20px auto;
  }

  .btn-primary {
    margin-top: 10px;
    width: 100%;
    padding: 15px !important;
  }
}

.dashboard {
  button {
    border: none;
    outline: none;
    background-color: transparent;

    svg {
      width: 32px;
      cursor: pointer;
    }
  }

  .current-articles {
    list-style: none;
    margin: 0;
    padding: 0;

    li {
      padding-bottom: 16px;

      @media screen and (max-width: $mobile_res) {
        .img-wrapper {
          margin-bottom: 15px !important;
        }

        .details {
          max-width: 100% !important;
        }
      }

      .img-wrapper {
        overflow: hidden;
        height: 120px;
        margin: auto;
        border-radius: 5px;

        @media screen and (max-width: $mobile_res) {
          height: 240px !important;
        }

        img {
          width: 100%;
          object-fit: cover;
          border-radius: 5px;
          cursor: pointer;
        }
      }

      .likes {
        font-size: 12px;
        font-weight: 600;
        padding: 8px;
        text-align: center;
        border-radius: 6px;
        background-color: rgba(var(--primary-rgb), 0.4);
      }

      .details {
        h5 {
          text-align: left;
          line-height: 28px;
          font-size: 18px;
          font-weight: 600;
        }

        p {
          font-size: 15px;
          margin: 6px 0 4px 0;
          line-height: 24px;
          opacity: 0.6;
        }
      }

      button {
        padding: 0;

        svg {
          width: 24px;
          stroke: var(--dark);

          &.filled {
            stroke: none;
            fill: var(--dark);
          }
        }

        &:hover {
          svg {
            stroke: var(--primary);

            &.filled {
              stroke: none;
              fill: var(--primary);
            }
          }
        }
      }

      &:not(:last-child) {
        border-bottom: 1px solid rgba(var(--dark-rgb), 0.2);
      }
    }
  }

  .current-countries {
    display: flex;
    flex-wrap: wrap;
    gap: 16px;
    list-style: none;
    margin: 0;
    padding: 0;

    @media screen and (max-width: $mobile_res) {
      justify-content: center;

      li {
        padding: 8px 12px !important;

        input {
          max-width: 160px;
        }
      }
    }

    li {
      width: fit-content;
      border-radius: 8px;
      padding: 10px 15px;
      text-align: center;
      justify-content: center;
      background-color: rgba(var(--dark-rgb), 0.05);

      input {
        border: none;
        border-bottom: 1px dotted rgba(var(--dark-rgb), 0.5);
        outline: none;
        padding: 0;
        background-color: transparent;
      }

      h5 {
        font-size: 18px;
      }

      button {
        margin: 0 0 0 6px;
        padding: 0;

        svg {
          width: 20px;
        }

        &:hover {
          svg {
            stroke: var(--primary);
          }
        }
      }
    }
  }
}

.new-article-modal {
  background-color: rgba(var(--light-rgb), 0.8);
  backdrop-filter: blur(25px);
  padding: 10px 20px 20px 20px;

  .modal-header {
    border: none;
    padding-bottom: 0;

    .btn-close {
      filter: var(--invert-rte-toolbar-color);
    }
  }

  .modal-footer {
    border: none;
    padding-top: 10px;

    .btn-primary,
    .btn-secondary {
      padding: 8px 10px;
    }
  }

  .modal-title {
    color: var(--dark) !important;
    font-weight: 600;
    font-size: 24px;
  }

  .form-group {
    margin-bottom: 14px;

    label {
      margin-bottom: 4px;
      font-weight: 500;
      font-size: 14px;
      opacity: 0.75;
    }

    textarea {
      min-height: 100px;
      resize: none;
    }
  }
}

.search-articles {
  list-style: none;
  margin: 0;
  padding: 0;

  li {
    padding-bottom: 16px;

    @media screen and (max-width: $mobile_res) {
      .img-wrapper {
        margin-bottom: 15px !important;
      }

      .details {
        max-width: 100% !important;
      }
    }

    .img-wrapper {
      overflow: hidden;
      // margin: auto;

      img {
        width: 100%;
        object-fit: contain;
        border-radius: 5px;
        cursor: pointer;
      }
    }

    .details {
      h5 {
        text-align: left;
        line-height: 28px;
        font-size: 18px;
        font-weight: 600;
      }

      p {
        font-size: 15px;
        margin: 6px 0 4px 0;
        line-height: 24px;
        opacity: 0.6;
      }
    }

    &:not(:last-child) {
      border-bottom: 1px solid rgba(var(--dark-rgb), 0.2);
    }
  }
}

.discover-articles {
  display: grid;
  grid-template-rows: masonry;
  list-style: none;
  padding: 0;
  gap: 28px;

  @media only screen and (min-width: 1024px) {
    grid-template-columns: repeat(3, 1fr);
  }

  @media screen and (max-width: $mobile_res) {
    grid-template-columns: repeat(1, 1fr);
  }

  li {
    width: fit-content;
    background: rgba(var(--dark-rgb), 0.01);
    border-radius: 20px;
    backdrop-filter: blur(16px);
    border: 1px solid rgba(var(--dark-rgb), 0.1);

    .main-img-wrapper {
      width: 100%;
      border-radius: 20px 20px 0 0;
      overflow: hidden;

      img {
        width: 100%;
        object-fit: cover;
        cursor: pointer;
      }
    }

    @media screen and (max-width: $mobile_res) {
      margin: auto;
      width: 90%;

      h4 {
        font-size: 16px !important;
      }
    }

    .content {
      padding: 20px;

      h4 {
        font-weight: 600;
        color: var(--dark);
        font-size: 24px;
        cursor: pointer;
      }

      p {
        font-size: 15px;

        strong {
          font-weight: 400;
        }
      }

      .author-wrapper {
        .img-wrapper {
          width: 60px;
          height: 60px;
          overflow: hidden;
          border-radius: 50%;

          img {
            width: 100%;
            object-fit: cover;
          }
        }

        .author-details {
          p {
            margin: 1px 0;

            &.name {
              font-size: 15px;
              font-weight: 500;
              opacity: 0.95;
            }

            &.date {
              font-size: 14px;
              opacity: 0.6;
            }
          }
        }
      }

      .action-wrapper {
        .likes {
          display: flex;
          gap: 8px;
          align-items: center;
          padding: 6px 12px;
          border-radius: 50px;
          background-color: rgba(var(--primary-rgb), 0.2);

          p {
            margin: auto;
            font-size: 14px;
            cursor: pointer;
          }

          svg {
            width: 24px;
            fill: rgba(var(--primary-rgb), 0.75);
            cursor: pointer !important;
          }

          &:hover {
            background-color: rgba(var(--primary-rgb), 0.5);

            svg {
              fill: var(--primary);
            }
          }
        }

        p {
          font-size: 13px;
          opacity: 0.8;
        }
      }
    }

    &:hover {
      background: rgba(var(--dark-rgb), 0.02);
      box-shadow: 0 10px 25px -5px rgb(0 0 0 / 0.03),
        0 8px 20px -6px rgb(0 0 0 / 0.03);
    }
  }
}

.ReactTags__selected {
  margin-top: 20px;

  .ReactTags__tag {
    background-color: rgba(var(--dark-rgb), 0.05);
    width: fit-content;
    margin: 0 auto;
    margin-right: 8px;
    padding: 10px 4px 10px 10px;
    border-radius: 5px;
  }
}

.ReactTags__suggestions {
  width: fit-content;

  li {
    list-style: none;
    margin: 0;
    padding: 0;
  }
}

.ReactTags__tagInputField {
  width: 100%;
  border: 1px solid rgba(var(--dark-rgb), 0.3) !important;
  border-radius: 10px !important;
  box-shadow: none !important;
  padding: 10px 18px !important;
  background-color: var(--light) !important;
  color: var(--dark) !important;
  outline: none !important;
  cursor: text;

  &:focus,
  &:active,
  &:hover {
    outline: 1px solid rgba(var(--primary-rgb), 0.15) !important;
  }

  &::placeholder {
    color: rgba(var(--dark-rgb), 0.4) !important;
  }
}

.toastify {
  position: static;
  margin: auto;
  bottom: 0;
  background-color: rgba(var(--primary-rgb), 0.5) !important;
  box-shadow: $boxShadow1 !important;
  border: 1px solid rgba(var(--primary-rgb), 0.5);
  border-radius: 10px !important;
  max-width: 275px;
  text-align: center;
  padding: 0px 10px !important;
  font-size: 14px;
  backdrop-filter: blur(10px);
}

.pagination {
  justify-content: center;
  display: flex;
  padding: 20px 0 0;
  list-style: none;
}

.page-item .page-link {
  position: relative;
  display: block;
  margin: 0px 8px 0px 0px;
  padding: 4px 12px !important;
  border-radius: 6px !important;
  border: 1px solid rgba(var(--dark-rgb), 0.5) !important;
  background: rgba(var(--dark-rgb), 0.05);
  box-shadow: none !important;
  color: var(--dark);
  font-weight: 500;
  text-decoration: none;
  cursor: pointer;
  transition: 0.1s ease;
}

.page-item a.page-link:hover {
  background-color: var(--darker);
  color: var(--light);
}

.page-item.active .page-link {
  color: var(--light) !important;
  border-color: var(--primary) !important;
  background-color: var(--primary) !important;
}

.page-item.disabled {
  cursor: not-allowed;
}

.page-item.disabled .page-link {
  opacity: 0.4;
}

.rte-wrapper {
  position: relative;
  overflow: hidden;

  .rte-toolbar {
    width: inherit;
    border-bottom: 0 !important;
    position: absolute;
    top: 10px;
    left: 10px;
    z-index: 2;

    @media (max-width: $mobile_res) {
      top: 0;
      left: 0;

      .ButtonGroup__root___3lEAn {
        .ButtonWrap__root___1EO_R {
          padding: 0 0 0 0 !important;
        }
      }
    }

    .InputPopover__root___3Hpj9 {
      width: fit-content;
      padding: 5px;

      .InputPopover__input___264Za {
        height: 35px;
        outline: none;
        cursor: text;
        color: black;
      }

      .InputPopover__checkOption___32S87 label span {
        color: black;
      }
    }

    .ButtonGroup__root___3lEAn {
      margin-right: 10px;

      .Dropdown__root___3ALmx {
        background-color: var(--light-accent);
        padding: 3px;

        select {
          border: none;
          padding-left: 6px;
          background: none;
          outline: none;

          option {
            color: black;
          }
        }
      }

      .ButtonWrap__root___1EO_R {
        border-radius: 3px;
        background-color: var(--light-accent);
        border: 1px solid rgba(var(--dark-rgb), 0.25);
        margin: 0 1px;
        padding: 3px 3px 0px 3px;

        button {
          background: none;

          span {
            cursor: pointer;
            filter: var(--invert-rte-toolbar-color);
          }

          &:disabled {
            opacity: 0.5;
            cursor: not-allowed;

            span {
              cursor: not-allowed;
            }
          }
        }
      }
    }
  }

  .rte-editor {
    margin-top: 45px;
    margin-left: -8px;
    height: 300px;
    cursor: text;

    @media (max-width: $mobile_res) {
      margin-top: 70px;
      margin-left: -16px;
    }
  }
}

footer {
  background-color: rgba(var(--dark-rgb), 0.01);
  padding: 30px 0;
  border-top: 1px solid rgba(var(--dark-rgb), 0.1);
  border-radius: 20px 20px 0 0;

  img {
    cursor: pointer;
  }

  .footnote {
    width: 75%;
    font-size: 10px;
    font-weight: 500;
    color: rgba(var(--dark-rgb), 0.75);
    opacity: 0.8;
    margin: auto;
    margin-top: 10px;
  }
}

::-webkit-scrollbar-track {
  background-color: rgba(var(--dark-rgb), 0.2);
}

::-webkit-scrollbar {
  width: 12px;
  height: 8px;
}

::-webkit-scrollbar-thumb {
  background-color: rgba(var(--dark-rgb), 0.5);
}

::-webkit-scrollbar-corner {
  background-color: transparent;
}

@media (max-width: $mobile_res) {
  ::-webkit-scrollbar {
    display: none;
  }
}
