$light: var(--light);
$light-accent: var(--light-accent);

$dark: var(--dark);
$darker: var(--darker);

$primary: var(--primary);
$primary-accent: var(--primary-accent);

$secondary: var(--secondary);

$font-primary: "Poppins", sans-serif;
$font-secondary: "Lora", serif;

$boxShadow1: 0px 1px 50px -10px rgba(var(--dark-rgb), 0.125);

$mobile_res: 992px;

:root {
  --light: #f9fafb;
  --light-rgb: 249, 250, 251;

  --light-accent: #f3f4f6;
  --light-accent-rgb: 243, 244, 246;

  --dark: #111827;
  --dark-rgb: 17, 24, 39;

  --darker: #0e131f;
  --darker-rgb: 14, 19, 31;

  --primary: #4f46e5;
  --primary-rgb: 79, 70, 229;

  --primary-accent: #a5b4fc;
  --primary-accent-rgb: 165, 180, 252;

  --secondary: #134e4a;
  --secondary-rgb: 19, 78, 74;

  --invert-rte-toolbar-color: invert(0);
}
